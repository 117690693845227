<template>
    <w-data-table
        outlined
        :loading="loading"
        :headers="headers"
        :items="collectionData"
        :filter-by="filterBy"
        hide-default-header
        :server-items-length="collection.total"
        searchable
        @change:options="fetchItems"
        @click:row="onRowClick"
    >
        <!-- filters -->
        <template #filters>
            <v-select
                v-if="departments.length > 1"
                v-model="filterByDepartment"
                :items="departments"
                item-text="title"
                item-value="uuid"
                :placeholder="$trans('Department')"
                dense
                hide-details
                clearable
            >
                <template #prepend-inner>
                    <w-icon dense value="DEPARTMENT"/>
                </template>
            </v-select>
        </template>
        <template #item.title="{ item }">
            <div class="d-flex py-2">
                <div>
                    <div class="font-weight-bold text--primary">{{ item.title }}</div>
                    <div class="caption d-block d-md-none">
                        <span>{{ item.department.title }} <br></span>
                        <i>({{ item.department.school.title }})</i>
                    </div>
                </div>
            </div>
        </template>
        <template #[`item.department`]="{ item }">
            <div>{{ item.department.title }}</div>
        </template>
        <template #[`item.school`]="{ item }">
            <div>{{ item.department.school.title }}</div>
        </template>
        <!-- actions -->
        <template #[`item.actions`]>
            <w-icon-next color="primary"/>
        </template>
    </w-data-table>
</template>

<script>
import { getCoursesCollection } from '@apps/school/api/courses'
import { getDepartmentsCollection } from '@apps/school/api/departments'

export default {
    name: 'CoursesOfMember',
    computed: {
        filterKey() {
            return this.$auth.isStudent()
                ? 'group'
                : 'teacher'
        },
        filterValue() {
            return this.$auth.isStudent()
                ? this.$auth.member.group_uuid
                : this.$auth.member.uuid
        },
        collectionData() {
            if(!this.collection || !this.collection.data) {
                return []
            }

            return this.collection.data.map(item => {
                return item
            })
        },
        headers() {
            return [
                {
                    text: this.$trans('Course'),
                    value: 'title'
                },
                {
                    text: this.$trans('Department'),
                    value: 'department'
                },
                {
                    text: this.$trans('School'),
                    value: 'school'
                },
                {
                    value: 'actions',
                    align: 'right',
                    sortable: false
                }
            ]
        }
    },
    watch: {
        filterByDepartment(uuid) {
            this.fetchItems({ department: uuid })
        }
    },
    data() {
        return {
            loading: false,
            collection: {
                data: []
            },
            filterBy: ['title'],
            filterByDepartment: null,
            filterByTeacher: null,
            departments: []
        }
    },
    async mounted() {
        await this.fetchDepartments()
    },
    methods: {
        async fetchItems(payload) {
            this.$lprogress.begin()
            this.loading = true

            payload = payload || {}
            payload[this.filterKey] = this.filterValue

            if(!payload.perPage) {
                payload.perPage = 25
            }

            try {
                const response = await getCoursesCollection(payload)
                this.collection = response.data.data
            } finally {
                this.$lprogress.end()
                this.loading = false
            }
        },
        async fetchDepartments() {
            this.$lprogress.begin()

            try {
                const response = await getDepartmentsCollection({
                    [this.filterKey]: this.filterValue
                })

                this.departments = response.data.data
            } finally {
                this.$lprogress.end()
            }
        },
        onRowClick(item) {
            this.$router.push({
                name: 'courses.details',
                params: { uuid: item.uuid }
            }).catch(() => {
            })
        }
    }
}
</script>

<style lang=scss>

</style>
