<template>
    <content-wrapper
        :breadcrumbs="breadcrumbs"
    >
        <courses-of-member/>
    </content-wrapper>
</template>

<script>
import ContentWrapper from '@/components/ContentWrapper'
import CoursesOfMember from '@apps/school/components/Course/CoursesOfMember'

export default {
    name: 'Courses',
    components: { CoursesOfMember, ContentWrapper },
    computed: {
        breadcrumbs() {
            return [
                {
                    text: this.$trans('Courses'),
                    to: { name: 'courses' }
                }
            ]
        }
    }
}
</script>

<style lang="scss">

</style>
